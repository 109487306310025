import React, { useCallback } from 'react';

import { AggTable } from 'api/APITypes';
import { ROW_HEIGHT } from 'components/query/TableExplorer/TableRow/TableRow';

import Button from '../../Button/Button';

interface TableRowHoverOverlayProps {
  hoveredIndex: number;
  table: AggTable;
  selected: boolean;
  picked: boolean;
  overlayRight: number;
  onPickTable(table: AggTable): void;
}

const TableRowHoverOverlay = React.memo((props: TableRowHoverOverlayProps) => {
  const { hoveredIndex, table, selected, picked, overlayRight, onPickTable } = props;

  const handlePickTable = useCallback(
    (e: any) => {
      e.stopPropagation();
      onPickTable(table);
    },
    [table, onPickTable],
  );

  return (
    <div
      className="tt-table-buttons"
      style={{
        height: `${ROW_HEIGHT}px`,
        top: `${hoveredIndex * ROW_HEIGHT}px`,
        right: `${overlayRight}px`,
      }}
    >
      <div className={'f-row-y-center ' + (selected ? 'bg-sec-blue-gray-100' : 'bg-pri-gray-100')}>
        <Button onClick={handlePickTable} size="small" variant="darkDullAction" className="mr-1">
          {picked ? 'Remove' : 'Select'}
        </Button>
      </div>
    </div>
  );
});

export default TableRowHoverOverlay;
