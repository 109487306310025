/**
 * This file calculates which columns can be SELECTed from
 * a given vertex by traversing all of its ancestors for the
 * columns they contribute to this vertex.
 */
import { AggTable } from 'api/APITypes';
import { Column } from 'api/columnAPI';

import {
  BiParentVertex,
  MonoParentVertex,
  SourceTable,
  TableColumn,
  HasSelectColumns,
  FlowchartQueryModel,
  getVertex,
  FlowchartVertex,
  DestinationSocketType,
} from '../model/FlowchartQueryModel';

// Columns that come from a SourceTable don't have a notion of a socket they
// came from or being picked. These properties are applied once
// the column is inherited by another vertex.
export interface SourceTableColumn extends Omit<TableColumn, 'socket' | 'picked'> {}

export function columnToSourceTableColumn(sourceTable: SourceTable, column: Column): SourceTableColumn {
  // This method assumes `.table` is defined.
  // We could throw an exception here but that is overkill.
  const table: AggTable = sourceTable.table as AggTable;
  const columnValue: SourceTableColumn = {
    // vertex.id + column name guarantees uniqueness.
    // table name is a developer debugging convenience.
    id: `${sourceTable.id}_${table.name}_${column.name}_id`,
    name: column.name,
    table,
    column,
  };
  return columnValue;
}

export function sourceTableToSourceTableColumns(sourceTable: SourceTable): SourceTableColumn[] {
  if (sourceTable.table === null) {
    return [];
  }

  const availableColumnValues: SourceTableColumn[] = sourceTable.columns.map((c) =>
    columnToSourceTableColumn(sourceTable, c),
  );
  return availableColumnValues;
}

// Returns the ColumnValues available to a vertex before it picks any.
export function getAvailableColumnValuesFor(
  fqm: FlowchartQueryModel,
  forVertexID: string | null,
): TableColumn[] {
  // Base case: It's empty. Return nothing.
  if (forVertexID === null) {
    return [];
  }

  const forVertex = getVertex(fqm, forVertexID);

  // Base case: It's source table. Return it's columns.
  if (forVertex.type === 'source_table') {
    const sourceTable = forVertex as SourceTable;
    const sourceTableColumns = sourceTableToSourceTableColumns(sourceTable);
    // Now that the source table's columns are inherited from another vertex,
    // apply properties that are created by the inherited column relationship.
    const availableColumnValues = sourceTableColumns.map((s) => ({
      ...s,
      // SourceTable columns are always picked.
      picked: true,
      // This is a temporary lie to make the base case work.
      // Socket is techincally undefined here because SourceTables do not have parent sockets.
      // This will immediately get overwritten by another recursive call of getAvailableColumnValuesFor().
      // getAvailableColumnValuesFor() should never be called directly by the app.
      socket: 'single' as DestinationSocketType,
    }));
    return availableColumnValues;
  }

  // At this point, we know the vertex does not define its own list of columns to return.
  // Recurse its ancestors for columns that it passes through.

  let inheritedColumnValues: TableColumn[] = [];

  if (forVertex.isMonoParent()) {
    const monoParent = forVertex as MonoParentVertex;
    if (monoParent.singleParentID) {
      inheritedColumnValues = getAvailableColumnValuesFromSocket(fqm, monoParent, 'single');
    }
  }

  if (forVertex.isBiParent()) {
    const biParent = forVertex as BiParentVertex;
    if (biParent.leftParentID && biParent.rightParentID) {
      inheritedColumnValues = [
        ...getAvailableColumnValuesFromSocket(fqm, biParent, 'left'),
        ...getAvailableColumnValuesFromSocket(fqm, biParent, 'right'),
      ];
    }
  }

  return inheritedColumnValues;
}

// Returns the ColumnValues picked by this vertex or
// ColumnValues inherited from it's ancestors of this vertex's socket.
export function getAvailableColumnValuesFrom(
  fqm: FlowchartQueryModel,
  fromVertexID: string | null, // The current vertex to search from
): TableColumn[] {
  // Base case: It's empty. Return nothing.
  if (fromVertexID === null) {
    return [];
  }
  const fromVertex = fqm.vertices.find((v) => v.id === fromVertexID) as FlowchartVertex;
  const availableToFromVertex = getAvailableColumnValuesFor(fqm, fromVertexID);
  // Commentary:
  // When a SavedFlowchartQueryModel is converted to a FlowchartQueryModel
  // it sets the table and column objects on any selected columns in the FlowchartQueryModel.
  // At some point in the future if we get in a scenario where the table and column objects
  // are not guaranteed to be set we may need to upgrade selectValuesFor()
  // to pull the table and column objects from availableToFromVertex to guarantee
  // the latest table objects.
  const selectedByFromVertex = selectValuesFor(availableToFromVertex, fromVertex);
  return selectedByFromVertex;
}

// Returns the ColumnValues picked by the ancestors of this vertex's socket.
export function getAvailableColumnValuesFromSocket(
  fqm: FlowchartQueryModel,
  fromVertex: FlowchartVertex | null, // The current vertex to search from
  fromSocket: DestinationSocketType, // The socket on the current vertex to search from
): TableColumn[] {
  // Base case: It's empty. Return nothing.
  if (fromVertex === null) {
    return [];
  }
  const socketVertexID = fromVertex.socketToParentID(fromSocket);
  const selectedBySocket = getAvailableColumnValuesFrom(fqm, socketVertexID);
  // Columns are available relative to a given socket.
  // Mark them as such.
  const fromValues = selectedBySocket.map((ic) => ({
    ...ic,
    socket: fromSocket,
  }));
  return fromValues;
}

/**
 * Before a HasSelectColumns vertex can pass availableColumns to its ancestor,
 * it needs filter down to its selectedColumns.
 * It also needs to apply any mutations to those columns that the ancestor should not
 * be aware of because those column mutations happen inside the selectingVertex.
 * @param inheritedColumnValues ColumnValues inherited by the selectingVertex.
 * @param selectingVertex The vertex selecting the inheritedColumnValues.
 * @returns Mutated set of inheritedColumnValues that appear as if they came from a SourceTable
 * but actually came from the selectingVertex.
 */
export function selectValuesFor(
  inheritedColumnValues: TableColumn[],
  selectingVertex: FlowchartVertex,
): TableColumn[] {
  if (selectingVertex.hasSelectColumns()) {
    const selectVertex = selectingVertex as HasSelectColumns;
    if (selectVertex.selectedColumns) {
      // Only pass along picked columns
      const pickedColumns = selectVertex.selectedColumns.filter((c) => c.picked);

      // Make aliases permanent in selectingVertex's descendants
      const selectedColumns = pickedColumns.map((myCV) => {
        const selected = { ...myCV };
        if (myCV.alias) {
          selected.name = myCV.alias;
          selected.alias = undefined;
        }
        return selected;
      });
      return selectedColumns;
    }
  }
  return inheritedColumnValues;
}
