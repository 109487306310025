/*
1. Maps callable functions to TableExplorerReducer actions.
2. Makes TableExplorer.tsx smaller by delegating a lot of code to this file.
*/
import React, { useContext, useReducer, useEffect, useCallback, useMemo } from 'react';

import { AggTable, ConnectorsBySchema, Tag } from 'api/APITypes';
import useDebounce from 'hooks/useDebounce';
import { SearchColumnContext } from 'model_layer/SearchColumnContext';
import { TableModelsContext } from 'model_layer/TableModelsContext';
import { UserPreferencesContext } from 'model_layer/UserPreferencesContext';

import { reducer, initialState, ViewMode, TableExplorerSearchState } from './TableExplorerReducer';

export interface TableExplorerReduction extends TableExplorerSearchState {
  allLoaded: boolean;
  anyLocal: boolean;
  anyError: string;
  columnsError: string;
  columnsIsLoading: boolean;
  columnsIsLocal: boolean;
  connectorsBySchema: ConnectorsBySchema;
  tags: Tag[];
  handleFilterChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setViewMode: (newMode: ViewMode, autoSet?: boolean) => void;
  handleToggleHideEmptyTables: () => void;
  handleToggleHideViews: () => void;
  setTableNamesInSql: (tableNamesInSql: string[]) => void;
  handleManuallyPinTable: (tableId: string, pinned: boolean) => void;
  handleToggleSchema: (schemaKey: string) => void;
  handleToggleTag: (tag: string) => void;
}

export type CustomTableFilter = (table: AggTable) => boolean;

export default function useTableExplorerReducer(
  eventLocation: string,
  customTableFilter?: CustomTableFilter,
): TableExplorerReduction {
  const tableModelsContext = useContext(TableModelsContext);
  const {
    searchColumnsByTableID,
    error: columnsError,
    isLoading: columnsIsLoading,
    isLocal: columnsIsLocal,
  } = useContext(SearchColumnContext);

  const noopTableFilter = useCallback(() => true, []);

  const {
    connectorsBySchema,
    tables: allTables, // AggTables with transforms and connectors appended by AggTableReducer.
    recentTables,
    favoriteTables,
    allLoaded,
    anyLocal,
    anyError,
    favoritesByTableID,
    tags,
  } = tableModelsContext;
  const [reducerState, dispatch] = useReducer(reducer, { ...initialState });
  const { hideEmptyTables, hideViews, schemasExpandedMap, tagsExpandedMap } = reducerState;
  const { userPreferences, updateUserPreferences } = useContext(UserPreferencesContext);

  useEffect(() => {
    if (allLoaded) {
      // This filters out transforms that don't yet exist as tables in the DB.
      // I wonder if this is still appropriate.
      // Also applies custom filter used in certain instances.
      // E.g. Filter BQ tables in create data alerts flow
      const existsAll = allTables
        .filter((table: AggTable) => table.django_thinks_exists_in_snowflake)
        .filter(customTableFilter || noopTableFilter);
      const existsRecents = recentTables.filter(
        (table: AggTable) => table.django_thinks_exists_in_snowflake,
      );
      const existsFavorites = favoriteTables.filter(
        (table: AggTable) => table.django_thinks_exists_in_snowflake,
      );
      const tableLists = {
        allTables: existsAll,
        recentTables: existsRecents,
        favoriteTables: existsFavorites,
      };
      dispatch({ type: 'SET_TABLE_LISTS', tableLists });
    }
  }, [allLoaded, allTables, recentTables, favoriteTables, customTableFilter, noopTableFilter]);

  useEffect(() => {
    dispatch({ type: 'SET_SEARCH_COLUMNS_BY_TABLE_ID', searchColumnsByTableID });
  }, [searchColumnsByTableID]);

  useEffect(() => {
    if (allLoaded) {
      dispatch({ type: 'SET_FAVORITES_BY_TABLE_ID', favoritesByTableID });
    }
  }, [allLoaded, favoritesByTableID]);

  useEffect(() => {
    if (userPreferences.tableExplorerShowViews !== undefined) {
      dispatch({ type: 'SET_HIDE_VIEWS', hideViews: !userPreferences.tableExplorerShowViews });
    }
    if (userPreferences.tableExplorerShowViews !== undefined) {
      dispatch({
        type: 'SET_HIDE_EMPTY_TABLES',
        hideEmptyTables: !userPreferences.tableExplorerShowEmptyTables,
      });
    }
  }, [userPreferences]);

  const debouncedUpdateFilteredTables = useDebounce(
    useCallback(() => {
      dispatch({ type: 'UPDATE_FILTERED_TABLES' });
    }, []),
    30,
    useMemo(() => ({ maxWait: 60 }), []),
  );

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    // Experimentally, extremely fast typing is about 0.05s a keystroke.
    // Normal typing is 0.2s a keystroke.
    // Rendering the entire list of tables is expensive.
    // Reduce the number of renders of the entire table list we do while the user
    // is actively typing separating the updating of the search filter and
    // the updating of the resulting list of warehouse tables into two discreate
    // actions. The former is updated immediately, and the later is debounced
    // to reduce excessive renders of list of warehouse tables.
    const newFilter = event.target.value.toLowerCase();
    dispatch({ type: 'SET_FILTER_ONLY', filter: newFilter });
    // Percieved performance tweak:
    // If the filter is empty, immediately update the list of tables.
    // Otherwise, assume the user is actively typing and debounce the the update.
    if (newFilter === '') {
      dispatch({ type: 'UPDATE_FILTERED_TABLES' });
    } else {
      debouncedUpdateFilteredTables();
    }
  };

  const setViewMode = useCallback(
    (newMode: ViewMode, autoSet?: boolean): void => {
      const oldMode = reducerState.viewMode;
      dispatch({ type: 'SET_VIEW_MODE', viewMode: newMode });
      const eventName = autoSet ? `${eventLocation} AutoSetViewMode` : `${eventLocation} SetViewMode`;
      analytics.track(eventName, {
        oldMode,
        newMode,
      });
    },
    [dispatch, reducerState.viewMode, eventLocation],
  );

  const handleToggleHideEmptyTables = useCallback(() => {
    const hide = !hideEmptyTables;
    dispatch({ type: 'SET_HIDE_EMPTY_TABLES', hideEmptyTables: hide });
    analytics.track(hide ? `${eventLocation} HideEmptyTables` : `${eventLocation} ShowEmptyTables`);
    updateUserPreferences({ tableExplorerShowEmptyTables: !hide });
  }, [hideEmptyTables, eventLocation, updateUserPreferences]);

  const handleToggleHideViews = useCallback(() => {
    const hide = !hideViews;
    dispatch({ type: 'SET_HIDE_VIEWS', hideViews: hide });
    analytics.track(hide ? `${eventLocation} HideViews` : `${eventLocation} ShowViews`);
    updateUserPreferences({ tableExplorerShowViews: !hide });
  }, [hideViews, eventLocation, updateUserPreferences]);

  const setTableNamesInSql = useCallback(
    (tableNamesInSql: string[]): void => {
      dispatch({ type: 'SET_TABLE_NAMES_IN_SQL', tableNamesInSql });
    },
    [dispatch],
  );

  const handleManuallyPinTable = useCallback(
    (tableId: string, pinned: boolean): void => {
      dispatch({ type: 'MANUALLY_PIN_TABLE', tableId, pinned });
      analytics.track(
        pinned ? `${eventLocation} ManuallyPinTable` : `${eventLocation} ManuallyUnpinTable`,
        {
          table_id: tableId,
        },
      );
    },
    [dispatch, eventLocation],
  );

  const handleToggleSchema = useCallback(
    (schemaKey: string) => {
      const expanded = !schemasExpandedMap[schemaKey];
      dispatch({ type: 'SET_SINGLE_SCHEMA_EXPANDED', schemaKey, expanded });
      analytics.track(expanded ? `${eventLocation} ExpandSchema` : `${eventLocation} CollapseSchema`);
    },
    [schemasExpandedMap, eventLocation],
  );

  const handleToggleTag = useCallback(
    (tag: string) => {
      const expanded = !tagsExpandedMap[tag];
      dispatch({ type: 'SET_SINGLE_TAG_EXPANDED', tag, expanded });
      analytics.track(expanded ? `${eventLocation} ExpandTag` : `${eventLocation} CollapseTag`);
    },
    [tagsExpandedMap, eventLocation],
  );

  // I think creating a new object here is OK because all of the rerender checks
  // happen on the properties inside this object.
  return {
    allLoaded,
    anyLocal,
    anyError,
    columnsError,
    columnsIsLoading,
    columnsIsLocal,
    connectorsBySchema,
    tags,
    ...reducerState,
    handleFilterChange,
    setViewMode,
    handleToggleHideEmptyTables,
    handleToggleHideViews,
    setTableNamesInSql,
    handleManuallyPinTable,
    handleToggleSchema,
    handleToggleTag,
  };
}
