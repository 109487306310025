import { ConnectorLog } from 'api/connectorsAPI';
import StatusIcon from 'components/primitives/icons/StatusIcon/StatusIcon';

interface LogStatusIconProps {
  log: ConnectorLog;
}

export default function LogStatusIcon(props: LogStatusIconProps) {
  const { log } = props;
  if (log.status === 'FAILURE' || log.status === 'FAILURE_WITH_TASK') {
    return <StatusIcon status="error" />;
  }
  if (log.status === 'CANCELED') {
    return <StatusIcon status="warning" />;
  }
  if (log.status === 'SUCCESSFUL' || log.status === 'RESCHEDULED') {
    return <StatusIcon status="success" />;
  }
  return <StatusIcon status="syncing" rotate={true} />;
}
