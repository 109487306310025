import React, { memo } from 'react';

import cn from 'classnames';

import Checkbox from 'components/inputs/basic/Checkbox/Checkbox';
import { BaseSearchableCheckboxPickerRowProps } from 'components/inputs/composite/SearchableCheckboxPicker/BaseSearchableCheckboxPicker';
import TextInputGroup from 'components/inputs/group/TextInputGroup/TextInputGroup';
import Popover from 'components/overlay/Popover/Popover';
import PopperTrigger from 'components/overlay/PopperTrigger/PopperTrigger';
import { TableColumn } from 'components/query/FlowchartEditor/model/FlowchartQueryModel';

import SelectColumnRowHoverCard from './SelectColumnsRowHoverCard/SelectColumnsRowHoverCard';

// Type serves an a reminder of what type of string to use.
export type ColumnValueID = TableColumn['id'];

export interface ColumnRowObject {
  columnValue: TableColumn;
  isChecked: boolean;
  hasChanged: boolean;
  aliasError: string;
  samples: string[];
  isBeingDragged: boolean;
  isDraggedOver: boolean;
}

// Props that do not change from row to row
// Wrap all of these methods in useCallback() so ColumnSearchableCheckboxPickerRow memoization will work.
export interface ColumnSearchableCheckboxPickerEveryRowProps {
  onToggleCheck(o: ColumnRowObject): void;
  onDragStart: (column: ColumnValueID, event: React.DragEvent<HTMLSpanElement>) => void;
  onDragEnd: () => void;
  onDragOver: (event: React.DragEvent<HTMLSpanElement>) => void;
  onDragEnter: (enteredColumn: ColumnValueID, event: React.DragEvent<HTMLSpanElement>) => void;
  onDragLeave: (leftColumn: ColumnValueID, event: React.DragEvent<HTMLSpanElement>) => void;
  onDrop: (droppedOnColumn: ColumnValueID, event: React.DragEvent<HTMLSpanElement>) => void;
  onUpdateAlias: (column: ColumnValueID, alias: string) => void;
}

export interface ColumnSearchableCheckboxPickerRowProps
  extends BaseSearchableCheckboxPickerRowProps<
    ColumnRowObject,
    ColumnSearchableCheckboxPickerEveryRowProps
  > {}

const ColumnSearchableCheckboxPickerRow = memo((props: ColumnSearchableCheckboxPickerRowProps) => {
  const {
    checkboxName,
    object,
    onToggleCheck,
    onDragStart,
    onDragEnd,
    onDragOver,
    onDragEnter,
    onDragLeave,
    onDrop,
    onUpdateAlias,
  } = props;

  const { columnValue, isChecked, hasChanged, aliasError, samples, isBeingDragged, isDraggedOver } =
    props.object;

  const { id, name, alias } = columnValue;

  const handleOnChangeAlias = (event: React.ChangeEvent<HTMLInputElement>) => {
    onUpdateAlias(id, event.target.value);
  };

  const handleOnClickAlias = (event: React.MouseEvent<HTMLInputElement>) => {
    // Prevent row click handler from checking/unchecking the row
    event.stopPropagation();
  };

  const involvedInDrag = isBeingDragged || isDraggedOver;

  const outerClass = cn('w-full p-2 flex flex-column cursor-pointer hover:bg-[rgba(0, 0, 0, 0.075)]', {
    'bg-sec-blue-gray-100': hasChanged,
    'opacity-25': isBeingDragged,
    'border border-sec-blue-gray-500': isBeingDragged,
    'bg-pri-success-200': isDraggedOver,
    'shadow-[0_0_0_0.3rem_var(--pri-success-400)]': isDraggedOver,
  });

  return (
    <div
      onClick={() => onToggleCheck(object)}
      className={outerClass}
      draggable={true}
      onDragStartCapture={(e) => onDragStart(id, e)}
      onDragEnd={onDragEnd}
      onDragOver={onDragOver}
      onDragEnter={(e) => onDragEnter(id, e)}
      onDragLeave={(e) => onDragLeave(id, e)}
      onDrop={(e) => onDrop(id, e)}
    >
      <div className="w-full flex justify-between items-center">
        <Checkbox
          variant="blue_gray"
          name={checkboxName}
          checked={isChecked}
          readOnly={true} // onChange is handled by the entire row, so the input is readOnly
        />
        <div className="'w-full p-1 grow">
          <div className="w-full f-between">
            <div className="flex-1">
              <PopperTrigger
                forceShow={involvedInDrag ? false : undefined}
                placement="top"
                triggers="hover"
                delay={{ show: 400, hide: 50 }}
                popoverProps={{ style: { maxWidth: '400px' } }}
                renderPopper={(popperProps) => {
                  return (
                    <Popover
                      content={<SelectColumnRowHoverCard columnValue={columnValue} samples={samples} />}
                      {...popperProps}
                    />
                  );
                }}
              >
                <div>{name}</div>
              </PopperTrigger>
            </div>
            <TextInputGroup
              name={name}
              value={alias || ''}
              error={aliasError}
              onChange={handleOnChangeAlias}
              onClick={handleOnClickAlias}
              groupClass="w-[300px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default ColumnSearchableCheckboxPickerRow;
