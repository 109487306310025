/*******************************************************************************
 * Uses BaseSearchableCheckboxPicker to create a picker for TableColumns.
 *
 * This file is designed to be used by ColumnSelector.tsx.
 * ColumnSelector is responsible for setting state after user input.
 * This file is responsible for converting state to JSX.
 ******************************************************************************/
import { useCallback } from 'react';

import BaseSearchableCheckboxPicker, {
  BaseSearchableCheckboxPickerListProps,
} from 'components/inputs/composite/SearchableCheckboxPicker/BaseSearchableCheckboxPicker';
import NoMatchesFoundAlert from 'components/widgets/alerts/NoMatchesFoundAlert/NoMatchesFoundAlert';
import NoObjectsAlert from 'components/widgets/alerts/NoObjectsAlert/NoObjectsAlert';

import ColumnSearchableCheckboxPickerRow, {
  ColumnRowObject,
  ColumnSearchableCheckboxPickerEveryRowProps,
} from './ColumnSearchableCheckboxPickerRow';

export interface ColumnSearchableCheckboxPickerProps
  extends Omit<
    BaseSearchableCheckboxPickerListProps<ColumnRowObject>,
    'getKey' | 'matchesFilter' | 'renderNoObjects' | 'renderNoMatchesFound' | 'renderHeaderRow'
  > {
  everyRowComponentProps: ColumnSearchableCheckboxPickerEveryRowProps;
  enableScrollListMode?: boolean;
}

const ColumnSearchableCheckboxPicker = (props: ColumnSearchableCheckboxPickerProps) => {
  const getKey = useCallback((row: ColumnRowObject) => row.columnValue.id, []);

  const matchesFilter = (row: ColumnRowObject, filter: string) =>
    row.columnValue.name.toLowerCase().includes(filter.toLowerCase());

  const alertSpacingClass = props.enableScrollListMode ? 'm-4' : 'my-4';
  const renderNoObjects = () => (
    <NoObjectsAlert className={alertSpacingClass} heading="You don't have any columns." />
  );

  const renderNoMatchesFound = () => (
    <NoMatchesFoundAlert className={alertSpacingClass} heading="No matching columns." />
  );

  const renderHeaderRow = () => (
    <div className="w-full pl-2 pr-3 pt-2 f-between font-bold">
      <div>Column</div>
      <div className="w-[300px]">Rename To (Optional)</div>
    </div>
  );

  /*******************************************************************************
   * enableScrollListMode:
   * Tweak the CSS so the search input stays attached to the top of the modal
   * and the columns scroll under it.
   * 1. enableScrollListMode is desirable when the ColumnSelector is the only
   *    input in a containing modal such as the SelectColumnsModal.
   * 2. enableScrollListMode is undesirable when the ColumnSelector is below other
   *    inputs in a containing modal such as the JoinModal.
   *
   * Normally, we'd like the div that is the parent of <ColumnSelector/> to apply padding.
   * We cannot do that when enableScrollListMode=true because we put overflow-auto on the listObjectClass below,
   * and that would result in clipping the box-shadow when you drag a row.
   * To avoid the clipping we put the div's padding on the ColumnSelector.
   ******************************************************************************/
  const scrollOverrides = props.enableScrollListMode
    ? {
        containerClass: 'h-full f-col',
        inputWrapperClass: 'pt-4 px-4',
        listClass: 'h-full',
        listObjectsClass: 'px-4 pb-4 overflow-auto',
        usesOwnPadding: true,
      }
    : { listClass: '!overflow-visible' };

  return (
    <BaseSearchableCheckboxPicker
      {...scrollOverrides}
      {...props}
      getKey={getKey}
      matchesFilter={matchesFilter}
      renderNoObjects={renderNoObjects}
      renderNoMatchesFound={renderNoMatchesFound}
      renderHeaderRow={renderHeaderRow}
      rowComponent={ColumnSearchableCheckboxPickerRow}
    />
  );
};

export default ColumnSearchableCheckboxPicker;
