import React, { useCallback } from 'react';

import { AggTable } from 'api/tableAPI';
import TablePickerExplorer from 'components/inputs/basic/TablePicker/TablePickerExplorer/TablePickerExplorer';
import Modal from 'components/layouts/containers/modals/Modal/Modal';
import { useDatabaseAccount } from 'context/AuthContext';

import { CreateAlertSource } from './useCreateDataAlerts';

export interface CreateDataAlertsModalProps {
  selectedTable: AggTable | null;
  onClose: (eventLocation: CreateAlertSource) => void;
  setSelectedTable: React.Dispatch<React.SetStateAction<AggTable | null>>;
  onSubmit: (table: AggTable, eventLocation: CreateAlertSource) => void;
}

const CreateDataAlertsModal: React.FC<CreateDataAlertsModalProps> = ({
  selectedTable,
  onClose,
  setSelectedTable,
  onSubmit,
}) => {
  const databaseType = useDatabaseAccount().type;

  const handlePickTable = (table: AggTable | null) => {
    // TODO(create-data-alerts): Determine if it's useful to log the initial event location for
    // selecting a table from the create modal even if it all happens in the list page
    if (table) {
      onSubmit(table, 'DataAlertsPage');
    }
  };

  const handleCloseModal = () => {
    // TODO(create-data-alerts): Determine if it's useful to log the initial event location for
    // closing the create modal even if it all happens in the list page
    onClose('DataAlertsPage');
  };

  const tablePickerFilter = useCallback(
    (table: AggTable) => {
      const { type, connector } = table;
      return (
        (type === 'transform' || type === 'snapshot' || (type === 'unmanaged' && connector !== null)) &&
        databaseType !== 'bigquery'
      );
    },
    [databaseType],
  );

  return (
    <Modal
      header="Create Alert: Select Table"
      cancelButton
      onClose={handleCloseModal}
      containerClass="w-[700px] h-[90%]"
    >
      <TablePickerExplorer
        selectedTable={selectedTable}
        tableDraggable={false}
        tablesNamesUsedInSql={[]}
        setSelectedTable={setSelectedTable}
        onPickTable={handlePickTable}
        customTableFilter={tablePickerFilter}
        hideTabs
      />
    </Modal>
  );
};

export default CreateDataAlertsModal;
