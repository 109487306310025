import React, { useRef, useCallback } from 'react';

import { CheckLg } from 'react-bootstrap-icons';

import { AggTable } from 'api/APITypes';

import { highlightSearch } from '../highlightUtils';
import { PassedToTableRowProps } from '../TableNameList';

export const ROW_HEIGHT = 36;

interface TableRowProps extends PassedToTableRowProps {
  table: AggTable;
  selected: boolean;
  picked?: boolean;
  draggable: boolean;
  inFolder?: boolean;
  hideSchema?: boolean;
}

const TableRow = React.memo((props: TableRowProps) => {
  const {
    table,
    highlightFilter,
    selected,
    picked,
    draggable,
    inFolder,
    hideSchema,
    onSetTable,
    onDoubleClickTable,
  } = props;
  const rowRef = useRef(null);

  const onDoubleClick = useCallback(
    (e: any) => {
      e.stopPropagation();
      onDoubleClickTable(table);
    },
    [table, onDoubleClickTable],
  );

  const onDragStart = (tableID: string, event: React.DragEvent<HTMLSpanElement>) => {
    event.dataTransfer.setData('text/plain', tableID);
  };

  let className = 'tt-table-list-row';
  if (selected) {
    className += ' tt-table-list-selected-row';
  }
  if (inFolder && !picked) {
    className += ' tt-table-list-indented-row';
  }

  /* Perf: <li>s are way slower to render than <div>s for some reason */
  return (
    <div
      ref={rowRef}
      draggable={draggable}
      onDragStart={(e) => onDragStart(table.id, e)}
      onClick={() => onSetTable(table)}
      onDoubleClick={onDoubleClick}
      className={className}
    >
      <div className="f-row-y-center">
        {picked && <CheckLg size={16} className="mr-3" />}
        <span>{highlightSearch(hideSchema ? table.name : table.full_name, highlightFilter)}</span>
      </div>
    </div>
  );
});

export default TableRow;
