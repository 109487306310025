import { AggTable } from 'api/tableAPI';
import Button from 'components/inputs/basic/Button/Button';

import AIAssistantTablePickerPickedTable from './AIAssistantTablePickerPickedTable';

interface AIAssistantTablePickerPickedTablesProps {
  pickedTables: Record<string, AggTable>;
  unpickTable: (table: AggTable) => void;
  onClearSelection: () => void;
}

export default function AIAssistantTablePickerPickedTables(
  props: AIAssistantTablePickerPickedTablesProps,
) {
  const { pickedTables, unpickTable, onClearSelection } = props;
  return (
    <div className="flex justify-between my-1">
      <div className="mx-2 my-1 flex flex-wrap gap-2">
        {Object.values(pickedTables).map((table) => (
          <AIAssistantTablePickerPickedTable table={table} onUnpickTable={() => unpickTable(table)} />
        ))}
      </div>
      <div className="w-20 flex justify-center mx-4 mt-1">
        <Button onClick={onClearSelection} variant="lightDullTransparent" className="ml-2" size="small">
          Clear All
        </Button>
      </div>
    </div>
  );
}
