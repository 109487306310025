import { AggTable } from 'api/tableAPI';
import IconButton from 'components/inputs/basic/Button/IconButton';

interface AIAssistantTablePickerPickedTableProps {
  table: AggTable;
  onUnpickTable: () => void;
}

export default function AIAssistantTablePickerPickedTable(
  props: AIAssistantTablePickerPickedTableProps,
) {
  const { table, onUnpickTable } = props;
  return (
    <div className="bg-pri-gray-100 text-sm text-pri-gray-900 h-9 w-auto inline-flex flex-row justify-start items-center p-2 rounded">
      <div>{table.full_name}</div>
      <IconButton
        icon="X"
        size="small"
        iconSize={16}
        variant="lightDullTab"
        className="!h-5 !p-0 ml-4"
        onClick={onUnpickTable}
      />
    </div>
  );
}
