import { useCallback, useMemo, useState } from 'react';

import InputGroup from 'components/inputs/group/InputGroup';
import ListboxGroup, {
  ListboxOption,
  ListboxValue,
} from 'components/inputs/group/ListboxGroup/ListboxGroup';

import { BaseModalProps } from '../../FlowchartEditor';
import { TableColumn, JoinType } from '../../model/FlowchartQueryModel';

import ColumnInput from '../ColumnInput';
import ColumnSelector from '../select_columns/SelectColumnsModal/ColumnSelector/ColumnSelector';
import useColumnSelector, {
  selectionsToSave,
} from '../select_columns/SelectColumnsModal/ColumnSelector/useColumnSelector';
import VertexModal from '../VertexModal/VertexModal';

import { validateJoin } from './useJoin';

const JOIN_TYPES = ['INNER', 'LEFT INNER', 'RIGHT INNER', 'OUTER'];

export interface SavableJoinProps {
  joinType: JoinType;
  leftColumn: string;
  rightColumn: string;
  selectedColumns?: TableColumn[];
}
export interface JoinModalProps extends SavableJoinProps, BaseModalProps<SavableJoinProps> {
  // These two props control the join "on" selectors.
  leftJoinOnColumns: TableColumn[];
  rightJoinOnColumns: TableColumn[];
  // The two props control the ColumnSelector
  availableColumnValues: TableColumn[];
  selectedColumnValues?: TableColumn[];
}

const JoinModal = (props: JoinModalProps) => {
  const {
    leftJoinOnColumns,
    rightJoinOnColumns,
    availableColumnValues,
    selectedColumnValues,
    onSave,
    onCancel,
  } = props;

  const [joinType, setJoinType] = useState<JoinType>(props.joinType);
  const [leftColumn, setLeftColumn] = useState<string>(props.leftColumn);
  const [rightColumn, setRightColumn] = useState<string>(props.rightColumn);

  const columnSelectorState = useColumnSelector(availableColumnValues, selectedColumnValues);
  const { currentSelections } = columnSelectorState;

  // Validate the form every time it changes
  const [joinErrors, , formError] = useMemo(() => {
    return validateJoin(leftColumn, rightColumn, currentSelections);
  }, [leftColumn, rightColumn, currentSelections]);

  const handleSave = () => {
    const toSave = selectionsToSave(availableColumnValues, currentSelections);
    onSave({ joinType, leftColumn, rightColumn, selectedColumns: toSave });
  };

  const handleJoinTypeChange = (newVal: ListboxValue) => {
    setJoinType(newVal?.toString() as JoinType);
  };

  const handleLeftColumnChange = useCallback((column: string) => {
    setLeftColumn(column);
  }, []);

  const handleRightColumnChange = useCallback((column: string) => {
    setRightColumn(column);
  }, []);

  const joinOptions = JOIN_TYPES.map((j) => {
    const option: ListboxOption = {
      label: j,
      value: j,
    };
    return option;
  });

  // TODO: Convert to Formik to use form validation.

  return (
    <VertexModal
      header="Join"
      containerClass="w-[700px] h-[90%]"
      error={formError}
      onCancel={onCancel}
      onSave={handleSave}
    >
      <div className="w-full h-full p-4 overflow-auto">
        <ListboxGroup
          label="Join Type"
          name="join_type"
          value={joinType}
          options={joinOptions}
          onChange={handleJoinTypeChange}
        />
        <div className="mt-4 flex align-top justify-between">
          <InputGroup
            name="left_column"
            label="Left Column"
            input={
              <ColumnInput
                value={leftColumn}
                columnValues={leftJoinOnColumns}
                onChange={handleLeftColumnChange}
              />
            }
            groupClass="w-full"
            error={joinErrors.leftColumn}
          />
          <div className="h-[42px] mx-2 mt-[22px]">
            <div className="h-full f-row-y-center">=</div>
          </div>
          <InputGroup
            name="right_column"
            label="Right Column"
            input={
              <ColumnInput
                value={rightColumn}
                columnValues={rightJoinOnColumns}
                onChange={handleRightColumnChange}
              />
            }
            error={joinErrors.rightColumn}
            groupClass="w-full"
          />
        </div>
        <InputGroup
          name="selected_columns"
          label="Select Columns"
          input={<ColumnSelector {...columnSelectorState} />}
          groupClass="w-full mt-4"
        />
      </div>
    </VertexModal>
  );
};

export default JoinModal;
