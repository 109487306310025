import { AggTable } from 'api/tableAPI';
import TablePickerExplorer from 'components/inputs/basic/TablePicker/TablePickerExplorer/TablePickerExplorer';
import Modal from 'components/layouts/containers/modals/Modal/Modal';

import AIAssistantTablePickerPickedTables from './AIAssistantTablePickerPickedTables';

interface AIAssistantTablePickerModalProps {
  selectedTable: AggTable | null;
  onClose: () => void;
  setSelectedTable: React.Dispatch<React.SetStateAction<AggTable | null>>;
  onClearSelection: () => void;
  pickTable: (table: AggTable) => void;
  unpickTable: (table: AggTable) => void;
  pickedTables: Record<string, AggTable>;
}

const AIAssistantTablePickerModal = (props: AIAssistantTablePickerModalProps) => {
  const {
    selectedTable,
    onClose,
    setSelectedTable,
    pickTable,
    unpickTable,
    onClearSelection,
    pickedTables,
  } = props;

  return (
    <Modal
      header="Select tables for your request (optional)"
      cancelButton
      onClose={onClose}
      containerClass="w-[700px] h-[90%]"
    >
      {Object.keys(pickedTables).length > 0 && (
        <AIAssistantTablePickerPickedTables
          pickedTables={pickedTables}
          unpickTable={unpickTable}
          onClearSelection={onClearSelection}
        />
      )}
      <TablePickerExplorer
        selectedTable={selectedTable}
        tableDraggable={false}
        tablesNamesUsedInSql={[]}
        setSelectedTable={setSelectedTable}
        multiPick={{ pickedTables, onUnpickTable: unpickTable }}
        onPickTable={pickTable}
        hideTabs
      />
    </Modal>
  );
};

export default AIAssistantTablePickerModal;
